import React, { PureComponent } from "react";
import Axios from "axios";
import { config, server } from "../../env";

export default class OfflineScreens extends PureComponent {
  state = {
    data: {
      offlineScreens: [],
    },
  };

  componentDidMount = () => {
    Axios.get(server + "/hexer/dashboard", config)
      .then((rsp) => {
        this.setState({
          data: rsp.data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { data } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0">Offline Screens</h3>
          </div>
        </div>
        {/* bootstrap repsonsive table  */}
        <div className="row justify-content-start my-4 g-4">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4>Offline Screens </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>User Email</th>
                        <th>Last Response</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.offlineScreens.map((screens, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{screens.name}</td>
                          <td>{screens.email}</td>
                          <td>
                            {new Date(screens.last_response).toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
