import React, { PureComponent } from "react";
import Axios from "axios";
import { config, server } from "../../env";
import Modal from "../../components/modal";
import Alert from "../../components/Alert";
import Spinner from "../../components/Spinner";

export default class Dashboard extends PureComponent {
  state = {
    data: {
      screens_expiring: [],
    },
    screenName: "",
    screenId: "",

    loader: "",
    message: "",
  };

  componentDidMount = () => {
    this.getDashboard();
  };

  getDashboard = () => {
    Axios.get(server + "/hexer/dashboard", config)
      .then((rsp) => {
        this.setState({
          data: rsp.data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setExpiry = async (e) => {
    e.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      id: this.state.screenId,
      expiry: e.target.expiry.value,
    };

    await Axios.post(server + "/hexer/set-expiry", params, config)
      .then((rsp) => {
        this.setState({
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          message: (
            <Alert className="alert-success">
              {err?.response?.data?.detail}
            </Alert>
          ),
        });
      });
    this.getDashboard();
    this.setState({
      loader: "",
    });
  };

  render() {
    const { data, screenName } = this.state;
    const { message, loader } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0">Dashboard</h3>
          </div>
        </div>
        <div className="row justify-content-start my-4 g-4">
          {/* Total Users */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">{data.users || "--"}</h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Total Users</h6>
              </div>
            </div>
          </div>

          {/* Total Partners */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">{data.partners || "--"}</h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Total Partners</h6>
              </div>
            </div>
          </div>

          {/* Total Screens */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">
                  {data.screens}{" "}
                  <small
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    <span className="text-success">
                      {data.onlineScreenCount || "--"}
                    </span>{" "}
                    /{" "}
                    <span className="text-danger">
                      {data.offlineScreenCount || "--"}
                    </span>
                  </small>
                </h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Total Screens</h6>
              </div>
            </div>
          </div>

          {/* Files */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">{data.contents || "--"}</h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Content Files</h6>
              </div>
            </div>
          </div>

          {/* Text Scroller */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">{data.textScrollers || "--"}</h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Total Text Scrollers</h6>
              </div>
            </div>
          </div>

          {/* Layouts */}
          <div className="col-md-4">
            <div className="card px-3">
              <div className="card-body mt-2 pb-2">
                <h2 className="mb-0">{data.layouts || "--"}</h2>
              </div>
              <div className="card-footer bg-transparent border-0 mb-2">
                <h6 className="card-title">Total Layouts</h6>
              </div>
            </div>
          </div>
        </div>

        {/* bootstrap repsonsive table  */}
        {data.screens_expiring.length > 0 ? (
          <div className="row justify-content-start my-4 g-4">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>All Screens By Expiry Date</h4>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User Name</th>
                          <th>User Email</th>
                          <th>Screen Name</th>
                          <th>App Version</th>
                          <th>Orientation</th>
                          <th>Expiry</th>
                          <th>Created</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.screens_expiring.map((screen, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{screen.username}</td>
                            <td>
                              <span>{screen.email}</span>
                            </td>
                            <td>{screen.name}</td>
                            <td>{screen.app_version}</td>
                            <td>{screen.orientation.toUpperCase()}</td>
                            <td>{new Date(screen.expiry).toDateString()}</td>
                            <td>{new Date(screen.created).toDateString()}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  this.setState({
                                    screenName: screen.name,
                                    screenId: screen.id,
                                  })
                                }
                                data-toggle="modal"
                                data-target="#setExpiryModal"
                              >
                                Set Expiry
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <Modal id="setExpiryModal" title={"Set Screen Expiry - " + screenName}>
          <div className="modal-body">
            <form onSubmit={this.setExpiry}>
              {message}
              <div className="form-group mb-3">
                <label htmlFor="">Expiry</label>
                <input
                  type="datetime-local"
                  name="expiry"
                  id="expiry"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  Set Expiry {loader}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </main>
    );
  }
}
