import React, { PureComponent } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { auth } from "../env";

export default class Sidebar extends PureComponent {
  state = {
    hours: new Date().getHours(),
    current_theme: localStorage.getItem("theme-mode"),
    active_tab: window.location.href.split("/")[3],
  };

  setItem = (key, value) => {
    localStorage.setItem(key, value);
  };

  getItem = (key) => {
    return localStorage.getItem(key);
  };

  componentDidMount = () => {
    JSON.stringify(this.state.current_theme) === "null"
      ? this.adaptive(this.state.hours)
      : this.state.current_theme === "dark"
      ? this.adaptive(19) // For dark mode
      : this.adaptive(8); // For light mode
  };

  adaptive = (hours) => {
    if (hours >= 18 || hours <= 7) {
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/dark-theme.css");
      this.setState({
        current_theme: "light",
      });
    } else {
      document
        .getElementById("theme")
        .setAttribute("href", "/assets/css/light-theme.css");
      this.setState({
        current_theme: "dark",
      });
    }
  };

  changeTheme = () => {
    if (this.getItem("theme-mode") === "dark") {
      this.setItem("theme-mode", "light");
      this.adaptive(8);
    } else {
      this.setItem("theme-mode", "dark");
      this.adaptive(19);
    }
  };

  set_active = (tab) => {
    this.setState({
      active_tab: tab,
    });
    document.getElementById("sidebarMenu").classList.remove("show");
  };

  logout = () => {
    localStorage.removeItem("adFP");
    localStorage.removeItem("adDate");
    window.location.href = "/";
  };

  render() {
    const { current_theme, active_tab } = this.state;
    return (
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block sidebar collapse pt-5"
      >
        <div className="text-center d-none d-md-block">
          <a className="navbar-brand" href="/">
            <img
              src="/assets/img/logo/signage-logo.png"
              height="50"
              width="100"
              className="navbar-brand-img mx-auto"
              alt="Wilyer Signage"
            />
          </a>
        </div>
        <div className="position-sticky pt-5">
          <ul className="nav flex-column">
            <li className="nav-item pb-3 text-left ml-3">
              <button
                className={
                  current_theme === null || current_theme === "light"
                    ? "btn btn-white btn-sm rounded-custom pl-3 pr-3 mr-1"
                    : "btn btn-dark btn-sm rounded-custom pl-3 pr-3 mr-1"
                }
                onClick={this.changeTheme}
                id="theme-mode"
              >
                {current_theme === null || current_theme === "light" ? (
                  <span id="theme-name">Light Mode</span>
                ) : (
                  <span id="theme-name">Dark Mode</span>
                )}
              </button>
            </li>
            <li className="nav-item">
              <Link
                to=""
                className={active_tab === "" ? "nav-link active" : "nav-link"}
                aria-current="page"
                onClick={() => this.set_active("")}
              >
                <Icon.Layout size={14} /> Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/users"
                className={
                  active_tab === "/users" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                onClick={() => this.set_active("/users")}
              >
                <Icon.Users size={14} /> Users
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/online-screens"
                className={
                  active_tab === "/online-screens"
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => this.set_active("/online-screens")}
              >
                <Icon.Monitor size={14} /> Screens Online
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/offline-screens"
                className={
                  active_tab === "/offline-screens"
                    ? "nav-link active"
                    : "nav-link"
                }
                aria-current="page"
                onClick={() => this.set_active("/offline-screens")}
              >
                <Icon.Monitor size={14} /> Screens Offline
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/willplay"
                className={
                  active_tab === "/willplay" ? "nav-link active" : "nav-link"
                }
                aria-current="page"
                onClick={() => this.set_active("/willplay")}
              >
                <Icon.Monitor size={14} /> Willplay
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className={
                  active_tab === "logout"
                    ? "nav-link text-danger active"
                    : "nav-link text-danger"
                }
                onClick={this.logout}
              >
                <Icon.Power size={14} color="#dc3545" /> Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
