import React, { Fragment, PureComponent } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Axios from "axios";
import Topbar from "./header/topbar";
import Sidebar from "./header/sidebar";
import DashboardRouter from "./routes/dashboard";
import { auth, server } from "./env";

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    if (
      Date.parse(new Date()) - Date.parse(localStorage.getItem("adDate")) >
      20 * 60 * 60 * 1000
    ) {
      localStorage.removeItem("adFP");
      localStorage.removeItem("adDate");
    }

    // From wilyer auth
    var token = window.location.href.split("/")[4];

    if (token !== undefined) {
      localStorage.setItem("adFP", token);
      localStorage.setItem("adDate", new Date());
    }

    this.state = {
      login: "wait",
    };
  }

  componentDidMount() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("adFP")}`,
      },
    };

    Axios.post(server + "/account/verify", {}, config)
      .then((rsp) => {
        if (rsp.data.hasOwnProperty("is_superuser")) {
          this.setState({
            login: true,
          });
          if (window.location.href.includes("/auth/")) {
            window.location.href = "/dashboard";
          }
        } else {
          window.location.href = auth + "/logout";
        }
      })
      .catch((error) => {
        this.setState({
          login: false,
        });
        window.location.href = auth + "/logout";
      });
  }

  render() {
    const { login } = this.state;
    if (login !== "wait") {
      if (!login) {
        window.location.href = auth + "/auth/";
      }
    }
    return (
      <Fragment>
        <BrowserRouter>
          <Fragment>
            {login === "wait" || login === false ? "" : <Topbar />}
            <div
              className={
                login === "wait" || login === false
                  ? "container-fluid d-none"
                  : "container-fluid"
              }
            >
              <div className="row">
                <Sidebar />
                <Switch>
                  <Route path="/" component={DashboardRouter} />
                </Switch>
              </div>
            </div>
          </Fragment>
        </BrowserRouter>
      </Fragment>
    );
  }
}
