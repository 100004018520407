import React from "react";

function Alert(props) {
  return (
    <div className={"alert rounded-lg " + props.className} role="alert">
      {props.children}
    </div>
  );
}

export default Alert;
