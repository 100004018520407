import React, { PureComponent } from "react";
import { Notification } from "react-notification";
import Axios from "axios";
import {
  server,
  config,
  signage_url,
  partner_url,
  enterprise_url,
} from "../../env";
import Spinner from "../../components/Spinner";
import Modal from "../../components/modal";
import Alert from "../../components/Alert";

export default class Dashboard extends PureComponent {
  state = {
    users: [],
    url: "/hexer/users",
    next: null,
    total_users: 0,
    current_user: "",
    show_more_button: "Show more",
    spinner: (
      <Spinner
        color={
          localStorage.getItem("theme-mode") === "dark" ? "#b5b5b7" : "#686769"
        }
      />
    ),

    userId: "",

    // add screen
    btn_spinner: <Spinner />,
    licenseLoader: "",
    message: "",
    notification: false,
    loader: "",
  };

  componentDidMount = () => {
    this.readUsers(this.state.url);
  };

  search = (event) => {
    this.readUsers(this.state.url + "?search=" + event.target.value, true);
  };

  show_more = (url) => {
    this.readUsers(url);
  };

  readUsers = (url, search = null) => {
    this.setState({
      show_more_button: this.state.spinner,
    });
    Axios.get(server + url, config)
      .then((rsp) => {
        this.setState({
          total_users: rsp.data.count,
          users: search
            ? rsp.data.results
            : this.state.users.concat(rsp.data.results),
          next:
            rsp.data.next == null
              ? null
              : rsp.data.next.replace(/^.*\/\/[^\/]+/, ""),
          show_more_button:
            rsp.data.next == null ? "All are caught up!" : "Show more",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateUser = (id, status = null, permission = null) => {
    const params = {
      status,
      permission,
    };

    Axios.put(server + "/hexer/user/" + id, params, config)
      .then((rsp) => {
        this.setState({
          notification: true,
          message: rsp.data.detail,
        });
      })
      .catch((err) => {
        this.setState({
          notification: true,
          message: err.response.data.detail,
        });
      });
  };

  getToken = (id) => {
    Axios.post(server + "/hexer/user/token/" + id, {}, config)
      .then((rsp) => {
        const token = rsp.data.token;
        const type = rsp.data.type;
        var url = "";

        if (type === "signage") url = signage_url + token;
        else if (type === "partner") url = partner_url + token;
        else if (type === "enterprise") url = enterprise_url + token;
        else url += token;

        window.open(url, "_blank");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  license = (e) => {
    e.preventDefault();
    this.setState({
      licenseLoader: this.state.spinner,
    });

    const params = {
      signage_license: e.target.signage_license.value,
      willplay_licenses: e.target.willplay_licenses.value,
    };

    Axios.post(server + "/hexer/license/" + this.state.userId, params, config)
      .then((rsp) => {
        this.setState({
          message: <Alert className="alert-success">{rsp.data.detail} </Alert>,
          licenseLoader: "",
        });
      })
      .catch((err) => {
        this.setState({
          message: (
            <Alert className="alert-danger">{err.response.data.detail} </Alert>
          ),
          licenseLoader: "",
        });
      });
  };

  changePassword = (e) => {
    e.preventDefault();
    this.setState({
      loader: <Spinner />,
    });

    const params = {
      password: e.target.password.value,
      userId: this.state.userId,
    };

    Axios.post(server + "/hexer/change-user-password", params, config)
      .then((rsp) => {
        this.setState({
          message: <Alert className="alert-success">{rsp.data.detail} </Alert>,
          loader: "",
        });
      })
      .catch((err) => {
        this.setState({
          message: (
            <Alert className="alert-danger">{err.response.data.detail} </Alert>
          ),
          loader: "",
        });
      });
  };

  render() {
    const {
      users,
      show_more_button,
      total_users,
      next,
      licenseLoader,
      notification,
      message,
      loader,
    } = this.state;

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <Notification
          isActive={notification}
          message={message}
          dismissAfter={4000}
          onDismiss={() => this.setState({ notification: false })}
        />
        <div className="card mt-4">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-md-6">
              <h3 className="card-title h5 mb-0 mt-1">Clients</h3>
            </div>
          </div>
        </div>
        <div className="card py-2 mt-4 mb-5">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between g-2">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <input
                  type="text"
                  className="form-control text-muted rounded-custom pl-3"
                  placeholder="Search by email"
                  onChange={this.search}
                  required
                />
              </div>
            </div>
          </div>

          <div className="card-body p-0 border-0">
            <div className="table-responsive">
              <table className="table table-striped table-hover mb-0">
                <thead>
                  <tr className="text-center text-muted">
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>TYPE</th>
                    <th>SIG. LIC</th>
                    <th>WILL. LIC</th>
                    <th>Joined</th>
                    <th>Operations</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((data, index) => (
                    <tr key={index}>
                      <td className="td-pt-3 text-center">#{index + 1}</td>
                      <td className="td-pt-3 text-center">{data.full_name}</td>
                      <td className="td-pt-3 text-center">
                        <a
                          target="_blank"
                          rel="noopner noreferrer"
                          className="text-decoration-none"
                          href={"mailto:" + data.email}
                        >
                          {data.email}
                        </a>
                      </td>
                      <td className="td-pt-3 text-center">
                        {data.is_partner
                          ? "Partner"
                          : data.is_enterprise
                          ? "Enterprise"
                          : data.is_staff
                          ? "Staff"
                          : "Signage User"}
                      </td>
                      <td className="td-pt-3 text-center">
                        {data.signage_licenses}
                      </td>
                      <td className="td-pt-3 text-center">
                        {data.willplay_licenses}
                      </td>
                      <td className="td-pt-3 text-center">
                        {new Date(data.date_joined).toDateString()}
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm mr-2"
                          onClick={() => this.getToken(data.id)}
                        >
                          Log-In
                        </button>
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="visually-hidden">
                              Toggle Dropdown
                            </span>
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.setState({
                                    userId: data.id,
                                  })
                                }
                                data-toggle="modal"
                                data-target="#license"
                              >
                                License
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.setState({
                                    userId: data.id,
                                  })
                                }
                                data-toggle="modal"
                                data-target="#changePassword"
                              >
                                Change Password
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.updateUser(
                                    data.id,
                                    data.is_active ? false : true,
                                    data.is_partner
                                      ? "partner"
                                      : data.is_enterprise
                                      ? "enterprise"
                                      : data.is_staff
                                      ? "staff"
                                      : "signage User"
                                  )
                                }
                              >
                                {data.is_active ? "Deactivate" : "Activate"}
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.updateUser(
                                    data.id,
                                    data.is_active,
                                    "partner"
                                  )
                                }
                              >
                                Make Partner
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.updateUser(
                                    data.id,
                                    data.is_active,
                                    "enterprise"
                                  )
                                }
                              >
                                Make Enterprise
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.updateUser(
                                    data.id,
                                    data.is_active,
                                    "signage user"
                                  )
                                }
                              >
                                Make Signage User
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() =>
                                  this.updateUser(
                                    data.id,
                                    data.is_active,
                                    "pdf_web"
                                  )
                                }
                              >
                                PDF & Web Support
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {users.length} of {total_users}
                </h6>
              </div>

              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm rounded-custom border px-3"
                  onClick={next == null ? null : () => this.readUsers(next)}
                >
                  {show_more_button}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal id="license" title="Update License">
          <div className="modal-body">
            <form onSubmit={this.license}>
              {message}
              <div className="form-group">
                <label className="mb-1" htmlFor="signage_license">
                  Signage License
                </label>
                <input
                  type="number"
                  name="signage_license"
                  id="signage_license"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <label className="mb-1" htmlFor="willplay_licenses">
                  Willplay License
                </label>
                <input
                  type="number"
                  name="willplay_licenses"
                  id="willplay_licenses"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group float-right mt-4">
                <button type="submit" className="btn btn-primary">
                  Update License {licenseLoader}
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal id="changePassword" title="Change User Password">
          <div className="modal-body">
            <form onSubmit={this.changePassword}>
              {message}
              <div className="form-group mt-3">
                <label className="mb-1" htmlFor="willplay_licenses">
                  New Password
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group float-right mt-4">
                <button type="submit" className="btn btn-primary">
                  Change Password {loader}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </main>
    );
  }
}
