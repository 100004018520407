import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../source/dashboard/Dashboard";
import Users from "../source/dashboard/Users";
import OnlineScreens from "../source/dashboard/OnlineScreens";
import OfflineScreens from "../source/dashboard/OfflineScreens";
import Willplay from "../source/dashboard/Willplay";

export default class AuthRouter extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/willplay" component={Willplay} />
        <Route path="/online-screens" component={OnlineScreens} />
        <Route path="/offline-screens" component={OfflineScreens} />
        <Route path="/users" component={Users} />
        <Route path="/" component={Dashboard} />
      </Switch>
    );
  }
}
