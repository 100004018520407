const cloud = window.location.href;
var server;

if (cloud.includes("localhost")) {
  server = "http://localhost:8000";
} else {
  server = "https://api.wilyersignage.com";
}

var auth;

if (server.includes("localhost") || server.includes("http://")) {
  auth = "http://localhost:3000";
} else {
  auth = "https://auth.wilyersignage.com";
}
module.exports = {
  server,
  auth,
  config: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("adFP")}`,
    },
  },
  recaptcha_SITE_KEY: "6LcfiE0aAAAAAAkyaJnLd8SuSBZ4RB2rq4A93CL6",
  signage_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3002/auth/"
      : "https://wilyersignage.com/auth/",
  partner_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3003/auth/"
      : "https://partnerv2.wilyersignage.com/auth/",
  enterprise_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3001/auth/"
      : "https://wilyersignage.com/auth/",
};
