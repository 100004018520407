import React, { PureComponent } from "react";
import Axios from "axios";
import { config, server } from "../../env";

export default class Willplay extends PureComponent {
  state = {
    screens: [],
  };

  componentDidMount = () => {
    this.getWillplay();
  };

  getWillplay = () => {
    Axios.get(server + "/hexer/willplay-screens", config)
      .then((rsp) => {
        this.setState({
          screens: rsp.data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { screens } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0">Willplay Deployments</h3>
          </div>
        </div>
        {/* bootstrap repsonsive table  */}
        {screens.length > 0 && (
          <div className="row justify-content-start my-4 g-4">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>All Willplay Screens</h4>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>User Email</th>
                          <th>Orientation</th>
                          <th>Created</th>
                        </tr>
                      </thead>
                      <tbody>
                        {screens.map((screen, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <span>{screen.email}</span>
                            </td>
                            <td>{screen.orientation.toUpperCase()}</td>
                            <td>{new Date(screen.created).toDateString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}
